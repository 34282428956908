// src/components/InputBox.js

import React, { useState, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close'; // 导入关闭图标
import './InputBox.css';

const InputBox = ({ onSend, onFileUpload, disabled, uploadedFiles, setUploadedFiles }) => {
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (message.trim() === '' && uploadedFiles.length === 0) return;
    onSend(message);
    setMessage('');
    setUploadedFiles([]); // 重置已上传文件
    fileInputRef.current.value = ''; // 重置文件输入
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      Array.from(files).forEach(file => {
        onFileUpload(file);
      });
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (fileName) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  return (
    <div className="input-box-container">
      {uploadedFiles && uploadedFiles.length > 0 && (
        <div className="uploaded-files-list">
          {uploadedFiles.map((file, index) => (
            <div key={index} className="uploaded-file-item">
              <a href={URL.createObjectURL(file)} download={file.name} className="uploaded-file-name">
                {file.name}
              </a>
              <CloseIcon
                className="remove-file-icon"
                onClick={() => handleRemoveFile(file.name)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="input-box">
        <textarea
          className="input-textarea"
          placeholder="Type a message..."
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <button className="upload-button" onClick={triggerFileUpload} disabled={disabled}>
          <AttachFileIcon />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          disabled={disabled}
          multiple // 允许多文件上传
        />
        <button className="send-button" onClick={handleSend} disabled={disabled}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default InputBox;
